<template>
    <div>
        <div v-show="open_sumsub_container" id="sumsub-websdk-container" />
    </div>          
</template>

<script type='text/javascript'>
import { mapGetters } from 'vuex';
import snsWebSdk from '@sumsub/websdk';

export default {
    name: 'SumSub',
    props: {
        id: {
            type: String,
            default: null,
        },
        service: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        lang: {
            type: String,
            default: 'en',
        },
    },
    data() {
        return {
            open_sumsub_container: false,
        }
    },
    computed: {
        ...mapGetters('ui', ['language']),
    },
    watch: {
        language() {
            this.launch();
        },
    },
    mounted() {
        this.launch();
    },
    methods: {
        getAccessToken() {
            return axiosAccount.post('/api/kyc/token', {
                    id: this.id,
                    service: this.service,
                })
                .then(response => response.data)
                .catch(err => {
                    const { status, data } = err.response;

                    if (status === 404) {
                        this.$router.push({ name: 'accounts404' });
                    } else {
                        this.$emit('update:error', data.error);
                        this.$emit('update:service-loading', false);
                    }

                    throw err;
                });
        },
        redirectStatusPage(message, status) {
            const stringify_message = JSON.stringify({ message });
            const encodedMessage = Buffer.from(stringify_message).toString('base64');

            this.$router.push({
                name: 'kycStatus',
                params: { status },
                query: { _v: encodedMessage }
            });
        },
        async launch() {
            let { token } = await this.getAccessToken();
            let config = { lang: this.language };

            if (this.email) {
                config.email = this.email;
            }

            let snsWebSdkInstance = snsWebSdk
            .init(token, () => this.getAccessToken())
            .withConf(config)
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            .on('idCheck.onReady', () => this.$emit('update:service-loading', false))
            .on('idCheck.onApplicantSubmitted', () => {
                this.redirectStatusPage('pending', 'success');
            })
            .on('idCheck.applicantStatus', (data) => {
                if (data?.reviewStatus === 'completed' && data.reviewResult?.reviewAnswer === 'GREEN') {
                    this.redirectStatusPage('pending', 'success');
                }

                if (data?.reviewStatus === 'completed' && data.reviewResult?.reviewAnswer === 'RED' && data.reviewResult?.reviewRejectType === 'FINAL') {
                    this.redirectStatusPage('declined', 'error');
                }
            })
            .build();

            this.open_sumsub_container = true;
            snsWebSdkInstance.launch('#sumsub-websdk-container');
        },
    },
};
</script>

<style lang='scss'>
.sumsub-skip-buttom {
    margin-bottom: 10px!important;
}
</style>