<template>
    <layout :cardLoading="serviceLoading">
        <div class="kyc">
            <component
                v-if="!error"
                v-bind="currentBindings"
                :is="currentService"
                :service-loading.sync="serviceLoading"
                :error.sync="error"
                @kyb-created="kybCreated"
            />
            <div
                class="alert-container"
                v-if="!!error">
                <b-alert fade variant="danger" show>
                    <p class="m-0">
                        <span class="fa fa-exclamation-circle" />
                        <template v-if="error instanceof Object">
                            {{ this.$t(error.key, error.parameters) }}
                        </template>
                        <template v-else>
                            {{ error }}
                        </template>
                    </p>
                </b-alert>
            </div>
            <actions v-if="!serviceLoading" :redirect-url="redirectUrl" />
        </div>
    </layout>
</template>

<script type='text/javascript'>
import SumSub from './providers/sumsub/sumsub.vue';
import Kyb from './providers/kyb/kyb.vue';
import Layout from '@/components/layout/layout.vue';
import Actions from './components/actions.vue';

export default {
    name: 'KYC',
    components: {
        SumSub,
        Kyb,
        Layout,
        Actions,
    },
    data() {
        return {
            error: null,
            currentService: null,
            servicesMap: {
                'sumsub': 'SumSub',
                'kyb': 'Kyb',
            },
            currentBindings: {},
            serviceLoading: true,
            redirectUrl: null,
        }
    },
    methods: {
        async load() {
            const serviceData = await this.getRouteParams();
            const { service, kyb } = serviceData;
            const defaultService = (service === 'sumsub' && kyb)
                ? 'kyb'
                : serviceData.service;

            this.loadService(defaultService, serviceData);
        },
        async loadService(service, serviceData) {
            const { id } = this.$route.query;
            const {
                access_token,
                email,
                lang,
                redirect_url,
                form_name,
                country
            } = serviceData;

            this.redirectUrl = redirect_url || null;
            const selected_service = this.servicesMap[service]

            if (selected_service === 'SumSub') {
                let { render_url } = await this.getApplicationStatus(id);
            
                if (render_url !== '') {
                    this.serviceLoading = false;
                    this.$router.push(render_url);
                    return;
                }

                this.currentBindings = {
                    id: id,
                    service: service,
                    accessToken: access_token,
                    email: email,
                    lang: lang,
                };
            } 
        
            if (selected_service === 'Kyb') {
                this.currentBindings = {
                    id: id,
                    formName: form_name,
                    country,
                };
            }

            this.currentService = selected_service;
        },
        async getRouteParams() {
            const { _v } = this.$route.query;

            if (!_v) {
                this.error = {
                    key: 'The request is required to have the {parameter} parameter.',
                    parameters: { parameter: '_v' },
                };
                this.serviceLoading = false;

                return;
            }

            const decodedServiceData = Buffer.from(_v, 'base64');
            const parsedServiceData = JSON.parse(decodedServiceData.toString())
            const { service } = parsedServiceData;

            if (!service) {
                this.error = {
                    key: 'The request is required to have the {parameter} parameter.',
                    parameters: { parameter: 'service' },
                };
                this.serviceLoading = false;

                return;
            }
            
            if (!this.servicesMap[service]) {
                this.error = {
                    key: 'Sorry, there is no service with the name {service}.',
                    parameters: { service: service },
                };
                this.serviceLoading = false;

                return;
            } 

            return parsedServiceData;
        },
        getApplicationStatus(id) {
            return axiosAccount.get(`/api/kyc/status/${id}`)
                .then(response => response.data)
                .catch(err => {
                    const { status, data } = err.response;

                    if (status === 404) {
                        this.$router.push({ name: 'accounts404' });
                    } else {
                        this.error = data.error;
                        this.serviceLoading = false;
                    }

                    throw err;
                });
        },
        async kybCreated(status) {
            this.serviceLoading = true;
            const serviceData = await this.getRouteParams();
            const { kyc } = serviceData;

            if (!kyc) {
                const message = JSON.stringify({ message: status.message });
                const encodedMessage = Buffer.from(message).toString('base64');

                this.$router.push({
                    name: 'kycStatus',
                    params: { status: status.type },
                    query: { _v: encodedMessage }
                });

                return;
            }

            this.loadService('sumsub', serviceData);
        }
    },
    mounted() {
        this.load();
    }
};
</script>

<style lang='scss'>
.kyc {
    .alert-container {
        margin: 60px auto 30px;
        padding-left: 30px;
        padding-right: 30px;
        width: 700px;
        max-width: 100%;
        text-align: center;
    }
}
</style>
